import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

class Vcard extends Component {

    componentDidMount(){
        var vCardsJS = require('vcards-js');

											//create a new vCard
											var vCard = vCardsJS();


											//set properties
												vCard.firstName = 'Rajesh';
											
												vCard.lastName = 'Kumar';
												vCard.organization = 'TRPW Strategic Partners';
											
												vCard.title = 'Managing Partner';
												// vCard.url = 'https://github.com/enesser';
												vCard.note = 'A Trusted Responsive Partner for Wealth (ISO 9001:2015 Certified Organisation)';
												vCard.url = 'https://www.trpwpartners.com';
												vCard.workPhone = '+91 124-459-4949';
												vCard.cellPhone = '+91 959-903-0470';

												//set email addresses
												vCard.workEmail = 'rajesh.kumar@trpwpartners.com';
												// vCard.workEmail = 'e.nesser@acme-corporation.tld';

												vCard.workAddress.label = 'TRPW Strategic Partners';
												vCard.workAddress.street = 'C-807, Unitech Business Zone, Golf Course Extn Road, Sector 50';
												vCard.workAddress.city = 'Gurgaon';
												vCard.workAddress.stateProvince = 'Haryana';
												vCard.workAddress.postalCode = '122018';
												vCard.workAddress.countryRegion = 'India';

												// vCard.workAddress.label = 'TRPW Innovation Center';
												// vCard.workAddress.street = '1059, LGF, Sector 47, Sohna Road';
												// vCard.workAddress.city = 'Gurgaon';
												// vCard.workAddress.stateProvince = 'Haryana';
												// vCard.workAddress.postalCode = '122001';
												// vCard.workAddress.countryRegion = 'India';

												// vCard.workAddress.label = 'USA Office';
												// vCard.workAddress.street = '24617 Greysteel Square';
												// vCard.workAddress.city = 'Aldie';
												// vCard.workAddress.stateProvince = 'VA';
												// vCard.workAddress.postalCode = '20105';
												// vCard.workAddress.countryRegion = 'United States of America';
 
												//save to file
												// vCard.saveToFile('./eric-nesser.vcf');

												const url = window.URL.createObjectURL(new Blob([vCard.getFormattedString()]));
												const link = document.createElement('a');
												link.href = url;
												link.setAttribute('download', 'vCard-Rajesh Kumar.vcf');
												document.body.appendChild(link);
												link.click();

                                                window.location.href = '/'
												
												//get as formatted string
												// console.log(vCard.getFormattedString());
                                         
    }

   render(){
       return(
       <div>
        
       </div>
       )
   }
}

export default Vcard;