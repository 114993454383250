import React, { Component } from "react";
import PostApiCall from "../Api/Api";
import Footer from "./Footer";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import HandIcon from "./HandIcon";



const items = [
  <img
    style={{ width: "100%", padding: "0 6px", height: "280px" }}
    src="/img/Award/BIA- Award photo.png"
    alt=""
    onClick={() => window.open("/img/Award/BIA- Award photo.png")}
    role="presentation"
  />,
  <img
    style={{ width: "100%", padding: "0 6px", height: "280px" }}
    src="/img/Award/receiving photo.png"
    alt=""
    onClick={() => window.open("/img/Award/receiving photo.png")}
    role="presentation"
  />,
  <img
    style={{ width: "100%", padding: "0 6px", height: "280px" }}
    src="/img/Award/brand icon award .JPG"
    alt=""
    onClick={() => window.open("/img/Award/brand icon award .JPG")}
    role="presentation"
  />,
  <img
    style={{ width: "100%", padding: "0 6px", height: "280px" }}
    src="/img/Award/brand icon.jpg"
    alt=""
    onClick={() => window.open("/img/Award/brand icon.jpg")}
    role="presentation"
  />,
  <img
    style={{ width: "100%", padding: "0 6px", height: "280px" }}
    src="/img/Award/award.jpeg"
    alt=""
    onClick={() => window.open("/img/Award/award.jpeg")}
    role="presentation"
  />,
  <img
    style={{ width: "100%", padding: "0 6px", height: "280px" }}
    src="/img/Award/Glory Award.png"
    alt=""
    onClick={() => window.open("/img/Award/Glory Award.png")}
    role="presentation"
  />,
  <img
    style={{ width: "100%", padding: "0 6px", height: "280px" }}
    src="/img/Award/Glory Award receiving.png"
    alt=""
    onClick={() => window.open("/img/Award/Glory Award receiving.png")}
    role="presentation"
  />,
  // <img
  //   style={{ width: "100%", padding: "0 6px", height: "280px" }}
  //   src="/img/Award/award44.jpeg"
  //   alt=""
  //   onClick={() => window.open("/img/Award/award44.jpeg")}
  //   role="presentation"
  // />,
  <img
    style={{ width: "100%", padding: "0 6px", height: "280px" }}
    src="/img/Award/Times Business Award Image Final.jpg"
    alt=""
    onClick={() =>
      window.open("/img/Award/Times Business Award Image Final.jpg")
    }
    role="presentation"
  />,
];

const responsive = {
  0: {
    items: 1,
  },
  768: {
    items: 2,
  },
  1024: {
    items: 3,
    itemsFit: "contain",
  },
};


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Company: "",
      Email: "",
      Mobile: "",
      Query: "",
      ButtonLocked: false,
      SendSuccess: false,
      ErrMsg: "",
      SuccessMessage: "",
      bulletPoints: [
        "Quality in Delivery",
        "Integrity in Behaviour",
        "Innovation in Thoughts",
        "Technology in Solutions",
        "Accountability in Actions",
      ],


    };
  }

  render() {
    return (
      <div className="App">
        <div class="swiper-main-slider-fade swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide slide-1"
            // style={{backgroundImage:'url(img/bg/home-banner1.png)', width : window.innerWidth, height : 'auto', maxWidth : '100%'}}
            >
              <div class="gradient-container">
                <img
                  class="web"
                  src="img/bg/home-banner1.png"
                  style={{ width: "100%", height: '80vh' }}
                  alt="banner"
                />
                <div class="gradient-overlay"></div>
              </div>

              {/* <img class="mob" src="img/bg/mobile-banner1.png" alt=""></img> */}

              <div class="container" style={{ position: "absolute" }}>
                <div class="slider-content left-holder">
                  <h2
                    class="animated fadeInDown"
                    style={{ marginTop: "2rem", textAlign: "center", color: "#ffffff" }}
                  >
                    Experienced, Personalized <br /> Consulting{" "}
                  </h2>
                  <div class="row" style={{ justifyContent: "center" }}>
                    <div class="col-md-12 col-sm-12 col-12">
                      <p
                        class="animated fadeInDown"
                        style={{ textAlign: "center", color: "#ffffff" }}
                      >
                        {" "}
                        A Trusted and Responsive <span style={{ color: "#ffffff" }}>Partner for Wealth</span>{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    class="animated fadeInUp mt-30 slidebtn"
                    style={{
                      // marginTop: "1rem",
                      textAlign: "center",
                      fontFamily: "serif",
                    }}
                  >
                    {" "}
                    <a href="/services" class="primary-button button-md">
                      KNOW ABOUT OUR SERVICES
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="swiper-slide slide-2"
              style={{
                backgroundImage: "url(img/bg/core_values.png)", backgroundSize: 'cover', backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            >
              {/* <img
                  class="web"
                  src="img/bg/core_values.png"
                  style={{ width: "100%", height: '80vh' }}
                  alt="banner"
                /> */}
              <div class="container">
                <div class="slider-content left-holder">
                  {/* <h2 class="animated fadeInDown .display-1" style={{ marginTop: "0rem" }}>
                    Core Values{" "}
                  </h2> */}
                  <div class="row">
                    <div
                      class="col-md-12 col-sm-12 col-12"
                      style={{ paddingLeft: "25px" }}
                    >
                      <p
                        class="animated fadeInDown"
                        style={{ color: "#ffffff", fontSize: "16px" }}
                      >
                        <div
                          className="row content-quality"
                          style={{ marginTop: "2rem" }}
                        >
                          <ul className="col-md-6 ml-3">
                            <li
                              className=""
                              style={
                                {
                                  // listStyleType: "",
                                  // fontSize: "19px",
                                  marginBottom: "1rem",
                                }
                              }
                            >
                              <HandIcon />   {" "}
                              Quality in Delivery
                            </li>
                            <li
                              style={
                                {
                                  // listStyleType: "disc",
                                  // fontSize: "19px",
                                  marginBottom: "1rem",
                                }
                              }
                            >   <HandIcon />   {" "}
                              Integrity in Behaviour
                            </li>
                            <li
                              style={
                                {
                                  // listStyleType: "disc",
                                  // fontSize: "19px",
                                  marginBottom: "1rem",
                                }
                              }
                            >   <HandIcon />   {" "}
                              Innovation in Thoughts{" "}
                            </li>
                            <li
                              style={
                                {
                                  // listStyleType: "disc",
                                  // fontSize: "19px",
                                  marginBottom: "1rem",
                                }
                              }
                            >
                              <HandIcon />   {" "}
                              Technology in Solutions
                            </li>
                            <li
                              style={
                                {
                                  // listStyleType: "disc",
                                  // fontSize: "19px",
                                  marginBottom: "1rem",
                                }
                              }
                            >   <HandIcon />    {" "}
                              Accountability in Actions
                            </li>
                          </ul>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div
                    class="animated fadeInUp mt-25 slidebtn"
                    style={{
                      marginLeft: '35px',
                      fontFamily: "serif",
                    }}
                  >
                    {" "}
                    <a href="/aboutus" class="primary-button button-md">
                      READ MORE
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-pagination"></div>
        </div>
        <div class="section-block">
          <div class="container">
            <div class="row">
              {/* <div class="col-md-4 col-sm-4 col-12"> <img src="img/content/home10.jpg" class="shadow-primary rounded-border" alt="img" /> </div> */}
              <div class="col-md-12 col-sm-12 col-12">
                <div class="pl-45-md mt-15">
                  <div class="section-heading center-holder">
                    <h3>Our Services</h3>
                    <div class="section-heading-line"></div>
                  </div>
                  <div class="row mt-30">
                    <div class="col-md-4 col-sm-6 col-12">
                      <div class="feature-box-2">
                        {" "}
                        <i class="icon-list-1"></i>
                        <h4>Internal Audit and Assurance Services</h4>
                        <p>
                          We help to evaluate the adequacy and effectiveness of
                          your company's internal controls, corporate
                          governance, and processes by offering the risk
                          advisory services.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-12">
                      <div class="feature-box-2">
                        {" "}
                        <i class="icon-money"></i>
                        <h4>CFO Special</h4>
                        <p>
                          Our CFO administrations gives top-level finance,
                          business, accounting, and functional direction for
                          your business.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-12">
                      <div class="feature-box-2">
                        {" "}
                        <i class="icon-money-1"></i>
                        <h4>Transaction Process Outsourcing (TPO)</h4>
                        <p>
                          TRPW is committed to ensuring the best possible
                          outcome for your transaction processing requirements.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-12">
                      <div class="feature-box-2">
                        {" "}
                        <i class="icon-line-graph-1"></i>
                        <h4>Virtual / Part-time CFO (VCFO)</h4>
                        <p>
                          We empower our clients to get assistance from our VCFO
                          services for their business improvement in settling
                          all of the issues and fulfil their business needs.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-12">
                      <div class="feature-box-2">
                        {" "}
                        <i class="icon-worldwide2"></i>
                        <h4>Business Expansions</h4>
                        <p>
                          Through a bespoke arrangement of qualified talent,
                          smart innovation and driving cycles we empower your
                          business to reach new heights through scalable
                          services.{" "}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-12">
                      <div class="feature-box-2">
                        {" "}
                        <i class="icon-briefcase"></i>
                        <h4>
                          Business planning and organization for Start-ups
                          (BPOS)
                        </h4>
                        <p>
                          Our business arranging and association administrations
                          will uphold you by handholding you in realizing your
                          thought into business reality in India or abroad.
                        </p>
                      </div>
                    </div>

                    {/* <div class="col-md-4 col-sm-6 col-12">
                      <div class="feature-box-2">
                        {" "}
                        <i class="icon-puzzle"></i>
                        <h4>Covid-19 Special Services</h4>
                        <p>
                          Covid pandemic is a troublesome time for all of us.
                          Relax, we are here to help in your business with our
                          Covid-19 uncommon administrations.
                        </p>
                      </div>
                    </div> */}
                    {/* <div class="col-md-4 col-sm-6 col-12"></div> */}

                    {/* removed */}
                    {/* <div class="col-md-4 col-sm-6 col-12">
                      <div class="feature-box-2">
                        {" "}
                        <i class="icon-laptop2"></i>
                        <h4>Audit Tech</h4>
                        <p>
                          We offer various additional services to fulfil your
                          business essentials.
                        </p>
                      </div>
                    </div> */}
                    {/* <div class="col-md-4 col-sm-6 col-12"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="section-block-bg"
          style={{ backgroundImage: "url(img/bg/bg15.jpg)" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <div class="section-heading">
                  {" "}
                  <span>Improve your business with us !</span>
                  <h3>We Help Clients Solve Complex Business Problems</h3>
                  <div class="section-heading-line-left"></div>
                </div>
                {/* <div class="text-content-big mt-20">
						<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse. cillum dolore eu.</p>
					</div> */}
                <div class="mt-25">
                  <ul class="primary-list">
                    <li>
                      <i class="fa fa-check-square"></i>Who We Are{" "}
                      <span>
                        <p style={{ fontWeight: "400", marginLeft: "20px" }}>
                          We are a boutique consulting firm supporting
                          entrepreneurs and senior executives across wide
                          variety of industries and business projects.{" "}
                          <a
                            style={{
                              color: "#c16d18",
                              fontWeight: "500",
                              cursor: "pointer",
                            }}
                            href="/aboutus"
                          >
                            Read more
                          </a>
                        </p>
                      </span>
                    </li>
                    <li>
                      <i class="fa fa-check-square"></i>What We Do{" "}
                      <span>
                        <p style={{ fontWeight: "400", marginLeft: "20px" }}>
                          We excel at creating business planning and structuring
                          solutions customised to your business and objectives.
                          We provide end-to-end solutions in the field of
                          finance, tax and accounting.{" "}
                          <a
                            style={{
                              color: "#c16d18",
                              fontWeight: "500",
                              cursor: "pointer",
                            }}
                            href="/services"
                          >
                            Read more
                          </a>
                        </p>
                      </span>
                    </li>
                    <li>
                      <i class="fa fa-check-square"></i>What Our Clients Say{" "}
                      <span>
                        <p style={{ fontWeight: "400", marginLeft: "20px" }}>
                          We want you to focus on what you do best, leave the
                          rest to us. Read about our client's experience.{" "}
                          <a
                            style={{
                              color: "#c16d18",
                              fontWeight: "500",
                              cursor: "pointer",
                            }}
                            href="/testimonials"
                          >
                            Read more
                          </a>
                        </p>
                      </span>
                    </li>
                  </ul>
                </div>
                {/* <div class="mt-35"> <a href="#" class="primary-button button-md">Discover More</a> </div> */}
              </div>
            </div>
          </div>
        </div>

        <div
          class="section-block border-bottom"
          style={{ paddingBottom: "0px" }}
        >
          <div class="container">
            <div class="section-heading center-holder">
              <h3>Our Membership & Certification</h3>
              <div class="section-heading-line"></div>
            </div>
            <div class="row mt-50" style={{ justifyContent: "center" }}>
              <div
                class="col-md-3 col-sm-6 col-12"
                style={{ marginBottom: "50px" }}
              >
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <img src="img/testmonials/cert1.png" alt="service" />
                </div>
              </div>
              <div class="col-md-1"></div>
              <div
                class="col-md-3 col-sm-6 col-12"
                style={{ marginBottom: "50px" }}
              >
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <img src="img/testmonials/cert2.png" alt="service" />
                </div>
              </div>
              <div class="col-md-1"></div>
              <div
                class="col-md-3 col-sm-6 col-12"
                style={{ marginBottom: "50px" }}
              >
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <img src="img/testmonials/cert3.png" alt="service" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="section-block border-bottom"
          style={{ paddingBottom: "0px" }}
          id="recognitions"
        >
          <div class="container">
            <div class="section-heading center-holder">
              <h3>Awards & Recognitions</h3>
              <div class="section-heading-line"></div>
            </div>
            <AliceCarousel
              responsive={responsive}
              autoPlay
              mouseTracking
              autoPlayInterval={1000}
              animationDuration={1000}
              animationType="fadeout"
              infinite
              // disableButtonsControls
              items={items}
            />
          </div>
        </div>

        <div class="section-block">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-12">
                <div class="pr-30-md">
                  <div class="section-heading">
                    <h4>Have Any Questions?</h4>
                    <div class="section-heading-line-left"></div>
                  </div>
                  <div class="text-content-big mt-25">
                    <p>
                      We will be happy to have a meeting/ call to discuss on how
                      best we can assist your organization and add value as a
                      partner in your organization’s growth, more than just
                      being a consultant.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-6 col-12">
                <div class="feedback-box">
                  <h5>
                    Just submit your details and we’ll be in touch shortly.
                  </h5>
                  <form
                    class="feedback-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <input
                          required
                          type="text"
                          name="subject"
                          placeholder="Name*"
                          value={this.state.Name}
                          onChange={(e) => {
                            this.setState({
                              Name: e.target.value,
                            });
                          }}
                        />{" "}
                      </div>
                      <div class="col-md-6">
                        <input
                          required
                          type="text"
                          name="name"
                          placeholder="Company Name*"
                          value={this.state.Company}
                          onChange={(e) => {
                            this.setState({
                              Company: e.target.value,
                            });
                          }}
                        />{" "}
                      </div>
                      <div class="col-md-6">
                        <input
                          required
                          type="email"
                          name="email"
                          placeholder="E-mail*"
                          value={this.state.Email}
                          onChange={(e) => {
                            this.setState({
                              Email: e.target.value,
                            });
                          }}
                        />{" "}
                      </div>
                      <div class="col-md-6">
                        <input
                          required
                          type="phone"
                          name="phone"
                          placeholder="Contact Number*"
                          value={this.state.Mobile}
                          onChange={(e) => {
                            this.setState({
                              Mobile: e.target.value,
                            });
                          }}
                        />{" "}
                      </div>
                      <div class="col-md-12">
                        <textarea
                          required
                          name="message"
                          placeholder="Your Query*"
                          value={this.state.Query}
                          onChange={(e) => {
                            this.setState({
                              Query: e.target.value,
                            });
                          }}
                        ></textarea>
                      </div>
                      <div class="col-md-12">
                        <div class="center-holder">
                          <button
                            type="submit"
                            disabled={this.state.ButtonLocked}
                            onClick={() => {
                              this.setState({
                                ErrMsg: "",
                                SuccessMessage: "",
                              });
                              if (
                                this.state.Name != "" &&
                                this.state.Company != "" &&
                                this.state.Email != "" &&
                                this.state.Mobile != "" &&
                                this.state.Query != ""
                              ) {
                                this.setState({
                                  ButtonLocked: true,
                                });
                                PostApiCall.postRequest(
                                  {
                                    name: this.state.Name,
                                    company: this.state.Company,
                                    email: this.state.Email,
                                    mobile: this.state.Mobile,
                                    query: this.state.Query,
                                  },
                                  "QueryMailer"
                                ).then((results) =>
                                  results.json().then((obj1) => {
                                    if (
                                      results.status === 200 ||
                                      results.status === 201
                                    ) {
                                      this.setState({
                                        SendSuccess: true,
                                        ButtonLocked: false,
                                        SuccessMessage:
                                          "Thank you for your message! We will get back to you within 48 hours.",
                                        Name: "",
                                        Company: "",
                                        Email: "",
                                        Mobile: "",
                                        Query: "",
                                      });
                                    } else {
                                      this.setState({
                                        ButtonLocked: false,
                                        ErrMsg:
                                          "Something wnet wrong, try again later.",
                                      });
                                    }
                                  })
                                );
                              }
                            }}
                          >
                            {this.state.ButtonLocked
                              ? "Sending..."
                              : "Send Message"}
                          </button>
                          <p style={{ color: "red" }}>{this.state.ErrMsg}</p>
                          <p style={{ color: "green" }}>
                            {this.state.SuccessMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Home;
