import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

class Services extends Component {
  componentDidMount() {
    document.title = "Services | TRPW Strategic Partners";
  }

  render() {
    return (
      <div>
        <div
          class="page-title-section"
          style={{ backgroundImage: "url(img/bg/servicesback.jpg)" }}
        >
          <div class="container">
            {/* <h1 style={{margin : '0px', fontFamily : 'serif'}}>Services</h1> */}
            {/* <div class='row'>
               <div className='col-sm-10'> */}
            <h1
              className="small-screen"
              style={{ margin: "0px", fontFamily: "serif", color: "#fff" }}
            >
              We are a Trusted and Responsive Partner to your Business
            </h1>
            {/* </div>
            <div className='col-sm-2'>
            <button class="primary-button button-sm small-screen-bt" type="submit"
										onClick={()=>{

                                            window.scrollTo(0,400)

                                        }}
                                        >FIND OUT MORE</button>
         </div>
            </div> */}
            {/* <ul>
				<li><a href="/">Home</a></li>
				<li><a href="/services">Services</a></li>
			</ul> */}
          </div>
        </div>
        <div class="section-block">
          <div class="container">
            <div class="section-heading center-holder">
              {" "}
              <span>Gain a Success With Us!</span>
              {/* <h3>We are a Trusted and Responsive Partner to your Business</h3> */}
              <h3>Services</h3>
              <div class="section-heading-line"></div>
              <p style={{ color: "#828080" }}>
                We provide customized business and strategic solutions through
                our industry experts for business structure modelling; long-term
                or short-term business plans and finance strategy, supporting in
                business expansion; assistance in CFO office functions, its
                implementation and compliances; transaction process outsourcing,
                amongst others. <br />
                <br />
                Speak with us on how we can assist you and your organization,
                and{" "}
                <b>provide value as a partner in your organization’s growth </b>
                , more than just being a consultant.{" "}
              </p>
            </div>
            <div class="row mt-50">
              <div class="col-md-12">
                <div class="service-block">
                  <div className="row">
                    <div className="col-sm-4" style={{ marginBlock: "auto" }}>
                      <img src="img/content/services/audit.jpg" alt="service" />
                    </div>
                    <div className="col-sm-8">
                      <div class="clearfix">
                        <div class="service-block-number">
                          <h5>01</h5>{" "}
                        </div>
                        <div class="service-block-title">
                          <h4>
                            <a href="#">
                              Internal Audit and Assurance Services
                            </a>
                          </h4>{" "}
                        </div>
                      </div>
                      <p className="new-list">
                        We assist organizations in achieving their objectives by
                        employing a systematic approach to evaluate and enhance
                        the adequacy and effectiveness of risk management,
                        control, and governance processes. Our offerings
                        include:
                        <br />
                        <ul>
                          <li>Internal & Process Audits. </li>
                          <li>Geographical Audits. </li>
                          <li>Risk Assurance.</li>
                          <li>Forensics. </li>
                          <li>Governance Services.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="service-block">
                  <div className="row">
                    <div className="col-sm-8" style={{ marginBottom: "10px" }}>
                      <div class="clearfix">
                        <div class="service-block-number">
                          <h5>02</h5>{" "}
                        </div>
                        <div class="service-block-title">
                          <h4>
                            <a href="#">CFO Special (CFOS)</a>
                          </h4>{" "}
                        </div>
                      </div>
                      <p className="new-list">
                        Our CFO Special caters exclusively to the office of the
                        Chief Financial Officer CFO), to support and lead
                        finance and tax functions effectively and efficiently,
                        streamlining the same as required. With CFO special, we
                        can lead in: <br />
                        <ul>
                          <li>Process mapping and automation. </li>
                          <li>Financial & tax efficiencies and control. </li>
                          <li>Accounting reconciliations.</li>
                          <li>Statutory compliances</li>
                          <li>
                            Design and implementation of management information
                            system.
                          </li>
                          <li>Managing ERP implementations.</li>
                          <li>Implementation of Internal Controls & Audit.</li>
                          <li>
                            Managing a special project or even training your
                            team to strengthen the outcomes of their efforts.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="col-sm-4 " style={{ marginBlock: "auto" }}>
                      <img
                        src="img/content/services/service3.png"
                        alt="service"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="service-block">
                  <div className="row">
                    <div className="col-sm-4" style={{ marginBlock: "auto" }}>
                      <img
                        src="img/content/services/service4.png"
                        alt="service"
                      />
                    </div>
                    <div className="col-sm-8" style={{ marginBottom: "10px" }}>
                      <div class="clearfix">
                        <div class="service-block-number">
                          <h5>03</h5>{" "}
                        </div>
                        <div class="service-block-title">
                          <h4>
                            <a href="#">
                              Transaction Process Outsourcing (TPO)
                            </a>
                          </h4>{" "}
                        </div>
                      </div>
                      <p className="new-list">
                        Our tax-finance-accounting outsourcing services take
                        care of finance, accounting and tax requirements for
                        different scale enterprises, in an effective and
                        proficient manner. Handled by teams which are
                        experienced in this field, we support your finance
                        function do value addition work than just focusing on
                        routine tasks. Services include: <br />
                        <ul>
                          <li>
                            Onsite or Offsite end to end accounting
                            transactions.
                          </li>
                          <li>Set-up of accounting systems.</li>
                          <li>
                            Accounting outsourcing services for specific
                            processes e.g. P2P, O2C, R2R, employee expense
                            claims etc.
                          </li>
                          <li>
                            Payroll processing, calculating payroll-related
                            taxes, preparing and filing payroll-related reports.
                          </li>
                          <li>
                            Preparation of month end reports and dashboards for
                            management or group reporting.
                          </li>
                          <li>Consolidation of accounts.</li>
                          <li>
                            Reviewing financial reporting, prepared either by
                            client employees or third parties.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="service-block">
                  <div className="row">
                    <div className="col-sm-8" style={{ marginBottom: "10px" }}>
                      <div class="clearfix">
                        <div class="service-block-number">
                          <h5>04</h5>{" "}
                        </div>
                        <div class="service-block-title">
                          <h4>
                            <a href="#">Virtual / Part-time CFO (VCFO)</a>
                          </h4>{" "}
                        </div>
                      </div>
                      <p className="new-list">
                        The role of a CFO today is about agility and ability to
                        respond fast and effectively to continuously evolving
                        business environments, disruptions, shortening business
                        cycles and of course, the increasingly complex
                        regulatory environments with focus on corporate
                        governance. <br />
                        <ul>
                          <li>
                            Your organization may be a start-up or small-medium
                            enterprise for which there may not even be a
                            requirement for a full-time CFO or maybe the cost is
                            prohibitive.{" "}
                          </li>
                          <li>
                            Or now that your business is expanding, your
                            business needs are growing which will require an
                            expert to manage the financial, budgeting and
                            regulatory requirements.{" "}
                          </li>
                          <li>
                            Or maybe it’s a transitionary phase for your
                            organization, with a search for a full-time CFO
                            underway.
                          </li>
                          <li>
                            The scenarios and requirements can be varied, to
                            which our part-time/ virtual CFO services can be
                            structured accordingly.
                          </li>
                          <li>
                            The objective is to provide you and your
                            organization a cost-effective solution with the
                            expertise, commitment and effectiveness of a CFO,
                            without actually hiring a full-time employee.{" "}
                          </li>
                          <li>
                            As your business requirement grows or stabilizes, we
                            can also assist organizations in hiring a full-time
                            CFO.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="col-sm-4" style={{ marginBlock: "auto" }}>
                      <img
                        src="img/content/services/service5.png"
                        alt="service"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="service-block">
                  <div className="row">
                    <div className="col-sm-4" style={{ marginBlock: "auto" }}>
                      <img
                        src="img/content/services/service2.png"
                        alt="service"
                      />
                    </div>
                    <div className="col-sm-8" style={{ marginBottom: "10px" }}>
                      <div class="clearfix">
                        <div class="service-block-number">
                          <h5>05</h5>{" "}
                        </div>
                        <div class="service-block-title">
                          <h4>
                            <a href="#">Business Expansions Services (BES)</a>
                          </h4>{" "}
                        </div>
                      </div>
                      <p className="new-list">
                        We assist existing organizations in their expansion
                        plans by: <br />
                        <ul>
                          <li>
                            Building a business and financial plan around their
                            objectives through detailed discussions.
                          </li>
                          <li>
                            Discuss the business and organization structuring of
                            the same, with a view on the overall organization
                            structure and business plan objectives.
                          </li>
                          <li>
                            Creating and managing the most efficient tax and
                            compliance structure.
                          </li>
                          <li>
                            Support in implementation for business expansions,
                            in India or overseas.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="service-block">
                  <div className="row">
                    <div className="col-sm-8" style={{ marginBottom: "10px" }}>
                      <div class="clearfix">
                        <div class="service-block-number">
                          <h5>06</h5>{" "}
                        </div>
                        <div class="service-block-title">
                          <h4>
                            <a href="#">
                              Business Planning and Organization for Start-ups
                              (BPOS)
                            </a>
                          </h4>{" "}
                        </div>
                      </div>
                      <p className="new-list">
                        <ul>
                          <li>
                            For a new business or a start-up entrepreneur, it is
                            critical for you to stay focussed on the business
                            idea and do what you do best.{" "}
                          </li>
                          <li>
                            A successful conversion of an idea to an actual
                            business requires expertise and effective
                            implementation.{" "}
                          </li>
                          <li>
                            Our business planning and organization services will
                            support you by hand-holding you in actualizing your
                            idea in terms of financial planning (and modelling),
                            business and organization structure, creating the
                            corporate eco-system and managing the statutory
                            compliances, in India or overseas.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="col-sm-4" style={{ marginBlock: "auto" }}>
                      <img
                        src="img/content/services/service1.jpg"
                        alt="service"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="service-block">
                  <div className="row">
                    <div className="col-sm-4" style={{ marginBlock: "auto" }}>
                      <img
                        src="img/content/services/service7.png"
                        alt="service"
                      />
                    </div>
                    <div className="col-sm-8" style={{ marginBottom: "10px" }}>
                      <div class="clearfix">
                        <div class="service-block-number">
                          <h5>07</h5>{" "}
                        </div>
                        <div class="service-block-title">
                          <h4>
                            <a href="/innovation"> Audit Tech</a>
                          </h4>{" "}
                        </div>
                      </div>
                      <p className="new-list">
                        As the digital transformation continues to reshape
                        business worldwide, <br />
                        <ul>
                          <li>
                            TRPW strives to establish high standards of
                            excellence by introducing innovative tools, enabling
                            organizations to make tech driven solutions for
                            efficient audits and strategic financial operations
                            and management.
                          </li>
                          <li>
                            Using advanced technology, we make the audit process
                            better, faster, and more cost-effective. Our audit
                            tech services include powerful data analysis, audit
                            automation, self audit tools, leading to better
                            decision-making and assurance for your business.
                          </li>
                          <li>
                            TRPW audit tech vision is forward-thinking and
                            aligns with the current and future technology trends
                            such as big data tools, AI/ML, RPA, web 3.0 and
                            metaverse.  <a href="/innovation"  style={{
                              color: "#c16d18",
                              fontWeight: "500",
                              cursor: "pointer",
                            }}>To know more..</a>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="col-md-12">
                <div class="service-block">
                  <div className="row">
                    <div className="col-sm-8" style={{ marginBottom: "10px" }}>
                      <div class="clearfix">
                        <div class="service-block-number">
                          <h5>06</h5>{" "}
                        </div>
                        <div class="service-block-title">
                          <h4>
                            <a href="#">Covid-19 Special Services</a>
                          </h4>{" "}
                        </div>
                      </div>
                      <p className="new-list">
                        This is a difficult time for all of us due to corona
                        virus pandemic. Don't worry, we are here to help in your
                        business with our Covid-19 special services as follows:{" "}
                        <br />
                     
                        <b>Cash Flow Sustainability Solutioning :</b>
                        <span>
                          {" "}
                          Managing cash flow is becoming a big challenge for
                          your business but a professional help can really do
                          wonder here. During this situation, you focus on what
                          you do best and leave rest to us.
                        </span>
                        <br />{" "}
                        <b>Assessment and Disclosure of Covid-19 Impacts :</b>
                        <span>
                          {" "}
                          How to do an assessment and disclose Covid-19 impacts
                          to your Financial Statements in accordance with the
                          prescribed guidelines? Let our expert do it for a fee
                          which makes sense to you.
                        </span>
                        <br />
                        <b>Business Transformation Services :</b>
                        <span>
                          {" "}
                          Difficult time difficult decisions? Allow us helping
                          you to restructure your business and creating the
                          maximum efficiencies be it key levers, finances,
                          systems or taxation.
                        </span>
                        <br />
                        <b>Receivables Management and Control :</b>
                        <span>
                          {" "}
                          It's time to redefine your credit limits and
                          collection processes. Sign with us to put professional
                          efforts in expediting your collections and re-draft
                          the structure of your credits because 'Credit' is the
                          soul of business.
                        </span>
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <img
                        src="img/content/services/service6.png"
                        alt="service"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div class="section-block-grey">
          <div class="container">
            <div class="section-heading center-holder">
              <h4>OUR SERVICES BROCHURE</h4>
              <div class="section-heading-line"></div>
            </div>

            <p style={{ textAlign: "center" }}>
              Please download our Brochure for a quick overview about us and our
              services.
              {/* <br /> */}
              {/* <br /> */}
              {/* Looking for the solution for your business in pandemic situation?
              We have launched Covid 19 Special Services, please download our
              brochure. */}
            </p>
            <div class="row mt-40" style={{ justifyContent: "center" }}>
              <div class="col-md-4 col-sm-6 col-12">
                <div class="testmonial-box">
                  <div class="row">
                    <div class="col-12">
                      <div class="center-holder">
                        <h5>TRPW Strategic Partners Brochure (pdf)</h5>
                        <a
                          href="img/testmonials/trpwbrochure-2.pdf"
                          target="_blank"
                          class="primary-button button-sm mt-3"
                        >
                          DOWNLOAD
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="col-md-4 col-sm-6 col-12">
                <div class="testmonial-box">
                  <div class="row">
                    <div class="col-12">
                      <div class="center-holder">
                        <h5>TRPW Brochure for Covid 19 Services (pdf)</h5>
                        <a
                          href="img/testmonials/trpwbrochurecovid19.pdf"
                          target="_blank"
                          class="primary-button button-sm mt-3"
                        >
                          DOWNLOAD
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
