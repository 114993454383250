import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer>
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-12">
                <h3>About Us</h3>
                <div class="mt-25">
                  {" "}
                  <img src="img/logos/TRPW-logo-footer.png" alt="footer-logo" />
                  <p class="mt-25">
                    TRPW Strategic Partners is a consulting firm based in Delhi-
                    NCR, India & USA supporting entrepreneurs and senior
                    executives across wide variety of industries and business
                    projects.
                  </p>
                  {/* <div class="footer-social-icons mt-25">
							<ul>
								<li><a href="#"><i class="fa fa-facebook-square"></i></a></li>
								<li><a href="#"><i class="fa fa-instagram"></i></a></li>
								<li><a href="#"><i class="fa fa-pinterest"></i></a></li>
								<li><a href="#"><i class="fa fa-skype"></i></a></li>
								<li><a href="#"><i class="fa fa-youtube"></i></a></li>
							</ul>
						</div> */}

                </div>
              </div>
              <div class="col-md-2 col-sm-6 col-12"></div>
              <div class="col-md-3 col-sm-6 col-12">
                <h3>Quick Links</h3>
                <ul class="footer-list">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/aboutus">About us</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/testimonials">What our clients say</a>
                  </li>
                  <li>
                    <a href="/contactus">Contact Us</a>
                  </li>
                </ul>
                <h3 className="mt-25">Careers with Us</h3>
                <p className="mt-25">
                  <i
                    class="fa fa-phone"
                    style={{ fontSize: "15px", marginRight: "10px" }}
                    aria-hidden="true"
                  />
                  <span>
                    {" "}
                    <a href="tel:(+91)9319179294" style={{ color: "#999" }}>
                      (+91) 9319179294
                    </a>
                  </span>
                  <br />
                  <i
                    class="fa fa-envelope"
                    style={{ fontSize: "15px", marginRight: "10px" }}
                    aria-hidden="true"
                  />
                  <span>
                    <a
                      href="mailto:hr@trpwpartners.com"
                      style={{ color: "#999" }}
                    >
                      hr@trpwpartners.com
                    </a>
                  </span>
                </p>
              </div>
              <div class="col-md-3 col-sm-6 col-12">
                <h3>Contact us</h3>
                <p class="mt-25">
                  <p>
                    <b>India Office : </b>C-807, Unitech Business Zone, Golf
                    Course Extn Road, Sector-50, Gurgaon -122018, Haryana, India
                  </p>
                  <p>
                    <b>Innovation Centre : </b>1059, LGF, Sector 47, Sohna Road,
                    Gurgaon-122001, Haryana, India
                  </p>
                  <p>
                    <b>USA Office : </b>24617, Greysteel SQ, Aldie, VA 20105
                  </p>
                  <br />
                  <i
                    class="fa fa-phone"
                    style={{ fontSize: "15px", marginRight: "10px" }}
                    aria-hidden="true"
                  />
                  <span>
                    <a href="tel:(+91)1244594949" style={{ color: "#999" }}>
                      (+91) 124 4594949
                    </a>
                  </span>
                  <br />
                  <i
                    class="fa fa-phone"
                    style={{ fontSize: "15px", marginRight: "10px" }}
                    aria-hidden="true"
                  />
                  <span>
                    {" "}
                    <a href="tel:(+91)9599030470" style={{ color: "#999" }}>
                      (+91) 9599030470
                    </a>
                  </span>
                  <br />
                  <i
                    class="fa fa-envelope"
                    style={{ fontSize: "15px", marginRight: "10px" }}
                    aria-hidden="true"
                  />
                  <span>
                    <a
                      href="mailto:info@trpwpartners.com"
                      style={{ color: "#999" }}
                    >
                      info@trpwpartners.com
                    </a>
                  </span>
                </p>
                <p>
                  <button
                    class="primary-button button-sm small-screen-bt"
                    type="submit"
                    style={{ marginTop: "1rem" }}
                    onClick={() => {
                      var vCardsJS = require("vcards-js");

                      //create a new vCard
                      var vCard = vCardsJS();

                      //set properties
                      vCard.firstName = "Rajesh";

                      vCard.lastName = "Kumar";
                      vCard.organization = "TRPW Strategic Partners";

                      vCard.title = "Managing Partner";
                      // vCard.url = 'https://github.com/enesser';
                      vCard.note =
                        "A Trusted Responsive Partner for Wealth (ISO 9001:2015 Certified Organisation)";
                      vCard.url = "https://www.trpwpartners.com";
                      vCard.workPhone = "+91 124-459-4949";
                      vCard.cellPhone = "+91 959-903-0470";

                      //set email addresses
                      vCard.workEmail = "rajesh.kumar@trpwpartners.com";
                      // vCard.workEmail = 'e.nesser@acme-corporation.tld';

                      vCard.workAddress.label = "TRPW Strategic Partners";
                      vCard.workAddress.street =
                        "C-807, Unitech Business Zone, Golf Course Extn Road, Sector 50";
                      vCard.workAddress.city = "Gurgaon";
                      vCard.workAddress.stateProvince = "Haryana";
                      vCard.workAddress.postalCode = "122018";
                      vCard.workAddress.countryRegion = "India";

                      // vCard.workAddress.label = 'TRPW Innovation Center';
                      // vCard.workAddress.street = '1059, LGF, Sector 47, Sohna Road';
                      // vCard.workAddress.city = 'Gurgaon';
                      // vCard.workAddress.stateProvince = 'Haryana';
                      // vCard.workAddress.postalCode = '122001';
                      // vCard.workAddress.countryRegion = 'India';

                      // vCard.workAddress.label = 'USA Office';
                      // vCard.workAddress.street = '24617 Greysteel Square';
                      // vCard.workAddress.city = 'Aldie';
                      // vCard.workAddress.stateProvince = 'VA';
                      // vCard.workAddress.postalCode = '20105';
                      // vCard.workAddress.countryRegion = 'United States of America';

                      //save to file
                      // vCard.saveToFile('./eric-nesser.vcf');

                      const url = window.URL.createObjectURL(
                        new Blob([vCard.getFormattedString()])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "vCard-Rajesh Kumar.vcf");
                      document.body.appendChild(link);
                      link.click();

                      //get as formatted string
                      console.log(vCard.getFormattedString());
                    }}
                  >
                    Download vCard
                  </button>
                </p>
                {/* <ul class="footer-list">
						<li><a href="#">Strategic Consulting</a></li>
						<li><a href="#">Sales Services</a></li>
						<li><a href="#">Market Research</a></li>
						<li><a href="#">Online Reputation</a></li>
						<li><a href="#">Strategic Consulting</a></li>
						<li><a href="#">Sales Services</a></li>
						<li><a href="#">Market Research</a></li>
					</ul> */}
              </div>
            </div>
            <div class="footer-bar">
              <p>
                <span class="primary-color">TRPW Strategic Partners</span> ©{" "}
                {new Date().getFullYear()}. All Rights Reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
