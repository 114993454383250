import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Vcard from "./Vcard";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Footer from "./Footer";
import Home from "./Home";
import Services from "./Services";
import Testimonials from "./Testimonials";
import Innovation from "./Innovation";
class MainMenu extends React.Component {
  state = {
    UserData: [],
  };
  render() {
    return (
      <>
        {/* {window.location.pathname === '/innovation' ?
          <Route exact path={"/innovation"} component={Innovation}  />
          : */}
        <div className="App">
          <Router>
            <div id="top-bar" class="hidden-sm-down">
              <div class="container">
                <div class="row">
                  <div class="col-md-8 col-12">
                    <div class="top-bar-welcome"></div>
                    <div class="top-bar-info">
                      <ul>
                        <li>
                          <i class="fa fa-phone"></i>
                          <a href="tel:(+91)1244594949" style={{ color: "#fff" }}>
                            (+91) 124 4594949
                          </a>
                        </li>
                        <li>
                          <i class="fa fa-envelope"></i>
                          <a
                            href="mailto:info@trpwpartners.com"
                            style={{ color: "#fff" }}
                          >
                            info@trpwpartners.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="top-bar-info">
                      <ul>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <header>
              <nav id="navigation4" class="container navigation">
                <div class="nav-header">
                  <a
                    class="nav-brand main-logo"
                    href="/"
                    style={{
                      fontFamily: "serif",
                      color: "#c16d18",
                      fontSize: "24px",
                    }}
                  >
                    {window.location.pathname === '/innovation' ? 'TRPW Innovation Centre' : 'TRPW Strategic Partners'}
                  </a>
                  <div class="nav-toggle"></div>
                </div>
                <div class="nav-menus-wrapper">
                  <ul class="nav-menu align-to-right">
                    <li>
                      <a href="/">HOME</a>
                    </li>
                    <li>
                      <a href="/aboutus">ABOUT US</a>
                    </li>
                    <li>
                      <a href="/services">SERVICES</a>
                    </li>
                    <li>
                      <a href="/innovation">Innovation</a>
                    </li>
                    <li>
                      <a href="/testimonials">WHAT OUR CLIENTS SAY</a>
                    </li>
                    <li>
                      <a href="/#recognitions">RECOGNITIONS</a>
                    </li>
                    <li>
                      <a href="/contactus">CONTACT US</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
          </Router>
          <Switch>
            <Route exact path={"/"} component={Home} />
            <Route exact path={"/innovation"} component={Innovation} />
            <Route exact path={"/aboutus"} component={AboutUs} />
            <Route exact path={"/services"} component={Services} />
            <Route exact path={"/contactus"} component={Contact} />
            <Route exact path={"/testimonials"} component={Testimonials} />
            <Route exact path={"/vcard"} component={Vcard} />
          </Switch>
          {window.location.pathname !== '/innovation' && 
          <Footer />
          }
        </div>
      </>
    );
  }
}

export default MainMenu;
