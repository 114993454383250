import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import PostApiCall from "../Api/Api";

class Contact extends Component {
	
	constructor(props){
		super(props)
		this.state={
			Name : '',
			Company : '',
			Email : '',
			Mobile : '',
			Query : '',
			ButtonLocked : false,
			SendSuccess : false,
			ErrMsg : ''
		}
	}

	componentDidMount() {
		document.title = 'Contact Us | TRPW Strategic Partners'

	  }

   render(){
       return(
        <div>
            	<div class="page-title-section" style={{backgroundImage: 'url(img/bg/contactus.jpg)'}}>
		<div class="container">
			<h1 style={{fontFamily : 'serif', color : '#fff'}}>Contact Us</h1>
			{/* <ul>
				<li><a href="/">Home</a></li>
				<li><a href="/contactus">Contact</a></li>
			</ul> */}
		</div>
	</div>
    <div class="section-block">
		<div class="container">
			<div class="row">
				<div class="col-md-7 col-sm-7 col-12">
					<div class="section-heading mt-15">
						<h4 style={{marginTop : '25px'}}>Contact Us</h4>
						<div class="section-heading-line-left"></div>
					</div>
					<div class="contact-form-box mt-30">
						<form class="contact-form" onSubmit={(e)=>{e.preventDefault()}} style={{display : this.state.SendSuccess ? 'none' : ''}}>
							<div class="row">
								<div class="col-md-12">
									<input required type="text" name="name" placeholder="Name*" 
									value = {this.state.Name}
									onChange = {(e) => {
										this.setState({
											Name : e.target.value
										})
									}}
									/> </div>
								<div class="col-md-12">
									<input required  type="text" name="name" placeholder="Company Name*" 
									value = {this.state.Company}
									onChange = {(e) => {
										this.setState({
											Company : e.target.value
										})
									}}
									/> </div>
								<div class="col-md-12">
									<input required  type="email" name="email" placeholder="E-mail*" 
									value = {this.state.Email}
									onChange = {(e) => {
										this.setState({
											Email : e.target.value
										})
									}}
									/> </div>
                                    <div class="col-md-12">
									<input required  type="phone" name="phone" placeholder="Contact Number*" 
									value = {this.state.Mobile}
									onChange = {(e) => {
										this.setState({
											Mobile : e.target.value
										})
									}}
									/> </div>
								<div class="col-md-12">
									<textarea required  name="message" placeholder="Your Query*"
									value = {this.state.Query}
									onChange = {(e) => {
										this.setState({
											Query : e.target.value
										})
									}}
									></textarea>
								</div>
								<div class="col-md-12 mt-40">
									<div class="center-holder">
										<button type="submit"
										disabled={this.state.ButtonLocked}
										onClick={()=>{
											this.setState({
												ErrMsg : '',
											})
											if(this.state.Name != '' && this.state.Company != '' && this.state.Email != '' && this.state.Mobile != '' && this.state.Query != ''){
												this.setState({
													ButtonLocked : true
												})
												PostApiCall.postRequest({
													name : this.state.Name,
													company : this.state.Company,
													email : this.state.Email,
													mobile : this.state.Mobile,
													query : this.state.Query
												  }, "QueryMailer").then((results) => results.json().then(obj1 => {
											  
													if (results.status === 200 || results.status === 201) {
														this.setState({
															SendSuccess : true,
															ButtonLocked : false
														})
													}else {
														this.setState({
															ButtonLocked : false,
															ErrMsg : 'Something wnet wrong, try again later.'
														})
													}
												}))


											}
										}}
										>{this.state.ButtonLocked ? 'Sending...' : 'Send'}</button>
										<p style={{color : 'red'}}>{this.state.ErrMsg}</p>
									</div>
								</div>
							</div>
						</form>
					
					<div style={{textAlign : 'center', display : this.state.SendSuccess ? '' : 'none'}}>
					<p><i class="fa fa-check-square" style={{color : 'green', fontSize : '30px'}}></i></p>
					<p>Thank you for your message!</p>
					<p>We will get back to you within 48 hours.</p>
					</div>
					</div>
				</div>
				<div class="col-md-5 col-sm-5 col-12">
					<div class="contact-info-box">
                    <div class="section-heading mt-15" style={{marginTop : '30px'}}>
						<h4>Its good to Connect and Talk!</h4>
						<div class="section-heading-line-left"></div>
					</div>
                        <p>We will be happy to have a meeting/ call to discuss on how best we can assist your organization and add value as a partner in your organization’s growth, more than just being a consultant. </p>
						<div className='row'>
							<div className='col-sm-7'>
							<a href="https://chatwith.io/s/rajesh-kumar" style={{display: 'table', fontFamily: 'sans-serif', textDecoration: 'none', marginTop: '1em', color: '#fff', fontSize: '0.8em', padding: '1em 2em 1em 3.5em', borderRadius: '2em', fontWeight: 'bold', background: '#25d366 url(https://tochat.be/click-to-chat-directory/css/whatsapp.svg) no-repeat 1.5em center', backgroundSize: '1.6em'}}>Message us on WhatsApp</a>
							</div>
						<div className='col-sm-5'>
						<button class="primary-button button-sm small-screen-bt" type="submit" style={{marginTop : '1rem'}}
										onClick={()=>{

											var vCardsJS = require('vcards-js');

											//create a new vCard
											var vCard = vCardsJS();


											//set properties
												vCard.firstName = 'Rajesh';
											
												vCard.lastName = 'Kumar';
												vCard.organization = 'TRPW Strategic Partners';
											
												vCard.title = 'Managing Partner';
												// vCard.url = 'https://github.com/enesser';
												vCard.note = 'A Trusted Responsive Partner for Wealth (ISO 9001:2015 Certified Organisation)';
												vCard.url = 'https://www.trpwpartners.com';
												vCard.workPhone = '+91 124-459-4949';
												vCard.cellPhone = '+91 959-903-0470';

												//set email addresses
												vCard.workEmail = 'rajesh.kumar@trpwpartners.com';
												// vCard.workEmail = 'e.nesser@acme-corporation.tld';

												vCard.workAddress.label = 'TRPW Strategic Partners';
												vCard.workAddress.street = 'C-807, Unitech Business Zone, Golf Course Extn Road, Sector 50';
												vCard.workAddress.city = 'Gurgaon';
												vCard.workAddress.stateProvince = 'Haryana';
												vCard.workAddress.postalCode = '122018';
												vCard.workAddress.countryRegion = 'India';

												// vCard.workAddress.label = 'TRPW Innovation Center';
												// vCard.workAddress.street = '1059, LGF, Sector 47, Sohna Road';
												// vCard.workAddress.city = 'Gurgaon';
												// vCard.workAddress.stateProvince = 'Haryana';
												// vCard.workAddress.postalCode = '122001';
												// vCard.workAddress.countryRegion = 'India';

												// vCard.workAddress.label = 'USA Office';
												// vCard.workAddress.street = '24617 Greysteel Square';
												// vCard.workAddress.city = 'Aldie';
												// vCard.workAddress.stateProvince = 'VA';
												// vCard.workAddress.postalCode = '20105';
												// vCard.workAddress.countryRegion = 'United States of America';
 
												//save to file
												// vCard.saveToFile('./eric-nesser.vcf');

												const url = window.URL.createObjectURL(new Blob([vCard.getFormattedString()]));
												const link = document.createElement('a');
												link.href = url;
												link.setAttribute('download', 'vCard-Rajesh Kumar.vcf');
												document.body.appendChild(link);
												link.click();
												
												//get as formatted string
												console.log(vCard.getFormattedString());
                                         

                                        }}
                                        >Download vCard</button>

						</div>
							</div>                        
                        <div class="row">
							<div class="col-md-12 col-sm-12 col-12">
								<div class="contact-info-section">
									<div class="row">
										<div class="col-md-2 col-sm-2 col-4 center-holder"> <i class="fa fa-phone"></i> </div>
										<div class="col-md-10 col-sm-10 col-8">
											<h4>Call Us</h4>
											<p><a href='tel:(+91)1244594949' style={{color : '#999'}}>(+91) 124 459 4949</a></p>
											<p> <a href='tel:(+91)9599030470' style={{color : '#999'}}>(+91) 959 903 0470</a></p>

										
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12 col-sm-12 col-12">
								<div class="contact-info-section">
									<div class="row">
										<div class="col-md-2 col-sm-2 col-4 center-holder"> <i class="fa fa-envelope-open"></i> </div>
										<div class="col-md-10 col-sm-10 col-8">
											<h4>E-mail</h4>
											<p><a href='mailto:info@trpwpartners.com' style={{color : '#999'}}>info@trpwpartners.com</a></p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12 col-sm-12 col-12">
								<div class="contact-info-section">
									<div class="row">
										<div class="col-md-2 col-sm-2 col-4 center-holder"> <i class="fa fa-globe"></i> </div>
										<div class="col-md-10 col-sm-10 col-8">
											<h4>Our Addresses</h4>
											{/* <p><h6>TRPW Strategic Partners</h6></p> */}
											<p><b>India Office : </b>C-807, Unitech Business Zone, Golf Course Extn Road, Sector-50, Gurgaon -122018, Haryana, India</p>
                                             <p><b>Innovation Centre : </b>1059, LGF, Sector 47, Sohna Road, Gurgaon-122001, Haryana, India</p>   
                                            <p><b>USA Office : </b>24617, Greysteel SQ, Aldie, VA 20105</p>
                                        </div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div id="map" class="mt-10">
	<div style={{overflow:'hidden', maxWidth:'100%', width:window.innerWidth, height:'400px'}}><div id="gmapdisplay" style={{height:'100%', width:'100%', maxWidth:'100%'}}><iframe style={{height:'100%', width:'100%', border:'0'}} frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Unitech+Bussiness+Zone,+Sector+50,+Gurugram,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="embeddedmap-code" rel="nofollow" href="https://www.embed-map.com" id="grabmap-authorization">https://www.embed-map.com</a></div>
	</div>
 </div>
       )
   }
}

export default Contact;