import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

class Testimonials extends Component {

	componentDidMount() {
		document.title = 'What our Clients Say | TRPW Strategic Partners'
	  }

   render(){
       return(
        <div>
            <div class="page-title-section" style={{backgroundImage: 'url(img/bg/testimonials.jpg)'}}>
		<div class="container">
			<h1 style={{fontFamily : 'serif', color : '#fff'}}>Testmonials</h1>
			{/* <ul>
				<li><a href="/">Home</a></li>
				<li><a href="/testmonials">Testmonials</a></li>
			</ul> */}
		</div>
	</div>

    <div class="section-block-bg" >
		<div class="container">
        <div className='row mb-50' >
        <a href="#" className='col-sm-6'>
						<div class="masonry-item"> <img src="img/projects/testimonial1.png" alt="project" />
						</div>
					</a>
                    <a href="#" className='col-sm-6'>
						<div class="masonry-item"> <img src="img/projects/testimonial2.png" alt="project" />
						</div>
					</a>
                    </div>

			<div class="section-heading center-holder"> <span>What our Clients say</span>
				<h3>CLIENT SPEAK!</h3>
				<div class="section-heading-line"></div>
			</div>
			<div>
				<div class="testmonial-single" style={{textAlign : 'center', borderBottom: '2px solid #ececec'}}>
                    <div>
                <img src="img/logos/svamlogo.png" alt="img" style={{width : '100px'}}/>
                </div>
					<p>“For a growth oriented organization like ours, the professional assistance and expertise we received from Rajesh and his team was immense. They were instrumental in helping our finance, accounting and tax processes become more robust. It was due to their contribution as well that we signed a Joint Venture deal with Toyo Packaging, Japan.
 <br/><br/>We have worked with the big accounting and consultancy firms as well, however, They gave us the right mix of focus, hands-on involvement, expertise and value (both in terms of contribution to our organization and cost effectiveness of services). 
 <br/><br/>Of course I recommend TRPW while we are ourselves are looking at seeking further assistance from them.” 
 </p>
					<h4>Mr. Vaibhav Ailawadi</h4>
					<h6>Director</h6>
                    <h6>Svam Packaging Industries (P) Ltd.</h6> </div>
				

                    <div class="testmonial-single" style={{textAlign : 'center'}}>
                    <div>
                <img src="img/logos/relicon.png" alt="img" style={{width : '100px', borderRadius : '50px'}}/>
                </div>
					<p>“I would thank Rajesh from TRPW for the commendable professional assistance they gave NIDA and helped NIDA in signing a business critical project with Reliance Education.
 <br/><br/>They gave a professional and expert impetus to our expansion venture by creating business plan, financial projections, and partnering with us as we discussed the venture with Reliance Education.  
 <br/><br/>From an initial idea of expansion in one state for a period of 5 years, to the signed collaboration with Reliance Education for 12 states for a period of 8 years; I am elated at the value that they brought for NIDA.” 
 </p>
					<h4>Mr. Ashish Gupta</h4>
					<h6>Managing Director, NIDA </h6>
                     </div>
			
			</div>
		</div>
	</div>
    </div>
       )
   }
}

export default Testimonials;