import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

class AboutUs extends Component {
  componentDidMount() {
    document.title = "TRPW Partners | TRPW Strategic Partners";
  }

  render() {
    return (
      <div>
        <div
          class="page-title-section"
          style={{ backgroundImage: "url(img/bg/aboutus.jpg)" }}
        >
          <div class="container">
            <h1 style={{ fontFamily: "serif", color: "#fff" }}>About Us</h1>
            {/* <ul> 
          <li>
            <a href="/">Home
            </a>
          </li>
          <li>
            <a href="/aboutus">About
            </a>
          </li>
        </ul>  */}
          </div>
        </div>
        <div class="section-block">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-sm-8 col-12">
                <div class="pr-30-md">
                  <div class="section-heading">
                    <h4>WHO WE ARE</h4>
                  </div>
                  <div class="section-heading-line-left"></div>
                  <div class="text-content-big mt-20">
                    <p>
                      TRPW Strategic Partners is a dynamic team consisting of
                      experienced finance and business professionals who have
                      corporate and consulting experiences, each spanning more
                      than a decade. <br />
                      We excel at creating business planning and structuring
                      solutions customized to your business and objectives.{" "}
                      <br />
                      We are a boutique consulting firm based in Delhi- NCR,
                      India & USA supporting entrepreneurs and senior executives
                      across wide variety of industries and business projects.{" "}
                      <br />
                      We provide customized business and strategic solutions
                      through our industry experts for business structure
                      modelling; long-term or short-term business plans and
                      finance strategy, supporting in business expansion;
                      assistance in CFO office functions, its implementation and
                      compliances; transaction process outsourcing, internal &
                      process audits amongst others. <br />
                      We want you to focus on what you do best, leave rest to
                      us.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-4 col-12"
                style={{ margin: "auto", paddingTop: "3rem" }}
              >
                <img
                  src="img/content/home5.jpg"
                  class="rounded-border shadow-primary mt-15-xs"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="section-block-bg"
          style={{ backgroundImage: "url(img/bg/bg11.jpg)" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-md-7 col-sm-12 col-12 offset-md-5">
                <div class="section-heading">
                  <span>Improve your business with us !</span>
                  <h4>WHY US!</h4>
                  <div class="section-heading-line-left"></div>
                  <div
                    class="text-content-big mt-10 new-list"
                    style={{ marginLeft: "15px" }}
                  >
                    <p>
                      <ul>
                        <li>
                          We are a dynamic team of experienced and qualified
                          professionals.
                        </li>
                        <li>
                          Provide value as partner in your growth, more than
                          just a consultant.
                        </li>
                        <li>
                          Proven record of providing value through our expertise
                          and experience, in a cost-effective manner.
                        </li>
                        <li>
                          Trusted partner to you – through our personalized
                          solutions, our expertise, individual attention and
                          responsiveness.
                        </li>
                        <li>Makes commercial sense for you.</li>
                        <li>
                          We are an ISO 9001:2015 (QMS) Certified Organization.
                        </li>
                      </ul>
                    </p>

                    <div class="mt-25">
                      <a href="#" class="primary-button button-sm">
                        FIND OUT MORE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="section-block">
          <div class="container">
            <div class="section-heading center-holder">
           
              <h3>OUR VALUES</h3>
              <div class="section-heading-line"></div>
              <div class="text-content-big mt-20 center-holder section-block-bg">
                <p className="center-holder ">
                <img
                  src="img/content/new.gif"
                  class="rounded-border  mt-15-xs"
                  alt="img"
                />
                </p>
              </div>
            </div>
          </div>
        </div> */}
      
        <div class="section-block">
          <div class="container">
            <div>
              {/* <div class="col-md-6 col-sm-6 col-12"> 
            <img src="img/bg/map.png" class="mb-15-xs" alt="map" /> 
          </div> */}
              <div class="col-md-12">
                <div class="section-heading">
                  <h4>OUR GENESIS</h4>
                  <div class="section-heading-line-left"></div>
                </div>
                <div class="text-content mt-25">
                  <p>
                    TRPW Strategic Partners is the idea of Rajesh Kumar, who is
                    a qualified Chartered Accountant and the Managing Partner of
                    TRPW Strategic Partners. His work experience spans over{" "}
                    {new Date().getFullYear() - new Date("2001").getFullYear()}{" "}
                    years, working at various senior management and
                    controllership positions in India as well as in regional
                    roles based in Malaysia, Singapore for MNCs. <br />
                    <br />
                    It was during his corporate tenure and his discussions with
                    other similarly placed corporate controllers, he realized
                    how consultants were simply “consultants” only. While what
                    the corporates value is a more cohesive and responsive
                    approach instead, where the consultant functions like a
                    partner. <br /> <br />
                    The experience, knowledge and expertise gained at the MNCs
                    and this idealogy lead to TRPW Strategic Partners, where our
                    expertise is combined with a responsive approach to become a
                    trusted partner to your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-block-grey">
          <div class="container">
            <div class="section-heading center-holder">
              {/* <span>Meet Our Team
          </span>  */}
              <h3>OUR MISSION</h3>
              <div class="section-heading-line"></div>
              <div class="text-content-big mt-20 center-holder">
                <p className="center-holder">
                  "To work as a Trusted Partner in a highly Responsive manner to
                  maximise your Wealth."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
