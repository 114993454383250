import React from "react";
import "./Innovation.modules.css";
const Innovation = () => {
  return (
    <>
      <div class="container-fuild">
        {/* <div class="innovation-header">TRPW Innovation Centre</div> */}

        <div class="innvocation-banner p-2">
          <div class="hero-text">INNOVATION IN THOUGHTS</div>
          <div class="hero-text">TECHNOLOGY IN SOLUTIONS</div>
          <div class="hero-inner">
            Transform ideas into business reality in Audit and Finance
          </div>

        </div>
      </div>
      <div class="container">
        <div class="Banner-section-title">ABOUT TRPW INNOVATION CENTRE</div>
        <div class="innovation">
          <div class="section">
            <div class="innovation-image">
              <img src="./img/innovation/pic1.webp" alt="" />
            </div>
            <div class="innovation-section">
              <h6>
                Our Mission - Empowering Audit Excellence through Innovation
              </h6>
              <p class="innovation-about-text">
                TRPW Innovation Centre is a tech unit of TRPW Strategic
                Partners. <br />
                At the forefront of the audit and finance industry, we are proud
                to introduce the Innovation Centre for Audit Tool and
                Technology. As the digital transformation continues to reshape
                businesses worldwide, the way we approach auditing and financial
                processes is evolving rapidly.
              </p>
            </div>
          </div>
          <div class="section">
            <div class="innovation-section">
              <h6>Our Vision - Catalysing Tomorrow's Excellence, Today</h6>
              <p class="innovation-about-text">
                We strive to establish higher standards of excellence by
                introducing innovative tools, enabling organizations to make
                tech-driven decisions for efficient audits and strategic
                financial management. Our commitment involves delivering
                solutions that meet current industry standards while positioning
                clients for success in the ever-evolving audit and finance
                sector.
              </p>
            </div>
            <div class="innovation-image">
              <img src="./img/innovation/pic2.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="Banner-section-title">OUR INNOVATIONS</div>
        <div class="row mb-5">
          <div class="col-md-4 mt-3">
            <div class="product-container">
              <img
                src="./img/innovation/card1.webp"
                class="product-image"
                alt="Betel Nut"
              />
              <div class="product-title">Betel Nut</div>
              <div class="product-description">
                Betel Nut is an audit management tool for large geographical
                project which provides a real-time status dashboard and
                analytics for audits. This tool facilitates auditor submissions
                through quality check workflows, ensuring comprehensive tracking
                at every level of field audit.
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-3">
            <div class="product-container">
              <img
                src="./img/innovation/card2.webp"
                class="product-image"
                alt="Betel"
              />
              <div class="product-title">Betel</div>
              <div class="product-description">
                Betel is an audit application with its different modules
                addressing to the needs of audit requirements for different
                scenarios and organizational needs. Betel provides solution to
                penetrate the future of geographical audits across the globe.
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-3">
            <div class="product-container">
              <img
                src="./img/innovation/card3.webp"
                class="product-image"
                alt="Limestone"
              />
              <div class="product-title">Limestone</div>
              <div class="product-description">
                Audit and Finance Industry require lot of innovation to
                implement in the process and limestone is one of them. Limestone
                automates the process of comparing an organization’s internal
                financial records with the transactions reported by the bank.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contact container-fluid">
        <div class="container contact-section">
          <div class="contact-header">CONTACT US</div>
          <div class="contact-content">
            <div class="contact-info">
              <h3>Stay updated!</h3>
              <p>Connecting Ideas, Driving Innovation.</p>
                <p>Get in touch with Us.</p>
              <p>A tech unit of TRPW Strategic Partners.</p>
              <h3 style={{ marginTop: "30px" }}>TRPW Innovation Centre</h3>
              <p>
                India Office: C-807, Unitech Business Zone, Golf Course Extn
                Road, Sector-50, Gurgaon - 122018, Haryana, India
              </p>
              <p>USA Office: 24617, Greysteel SQ, Aldie, VA 20105</p>
              <p>
                (<span style={{ color: "#d2a742" }}>+91) 124 4594949</span>
              </p>
              <p>
                (<span style={{ color: "#d2a742" }}>+91) 9599030470/1</span>
              </p>
              <p>
                Email:
                <a
                  style={{ color: "#d2a742" }}
                  href="mailto:info@trpwpartners.com"
                >
                  info@trpwpartners.com
                </a>
              </p>
            </div>
            <div class="contact-image">
              <img src="./img/innovation/final.webp" alt="Handshake" />
            </div>
          </div>
          <div class="footer">
            <h6>TRPW Innovation Centre</h6>
            <p>A tech unit of TRPW Strategic Partners</p>
            <p>
              Copyright © 2024 TRPW Innovation Centre - All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Innovation;
